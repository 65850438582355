<template>
  <div class="container">
    <!-- 打印拣货单 -->
    <el-button class="btn_e6a23c" @click="billPrintClick">打 印</el-button>
    <div ref="print" id="printBill">
      <!-- <img id="barcodes0"></img> -->
      <div
        class="mainBox"
        style="
          width: 100%;
          box-sizing: border-box;
          margin: 0 auto;
          page-break-after: always;
          font-size: 16px;
        "
        v-for="(item_a, index_a) in printData"
        :key="index_a"
      >
        <h3 class="mainTitle" style="text-align: center; width: 100%">
          集运拣货单
        </h3>
        <div
          class="topTips"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div class="topLeft" style="display: flex; align-items: center">
            <div class="qrcode" style="text-align: center; margin-right: 40px">
              <div>单据编号: {{ item_a.billPackNumber }}</div>
              <div>
                <img
                  style="width: 300px; height: 80px"
                  :id="'barcodes_' + index_a"
                />
              </div>
            </div>
            <!-- <div>
              <div>打包申请备注</div>
              <div>{{ item_a.billPackComment || "" }}</div>
            </div> -->
          </div>
          <div class="topRight">
            <div>
              <span
                style="width: 80px; text-align: right; display: inline-block;margin-right:4px;"
              >
                打印人: </span
              >{{ item_a.printUserName }}
            </div>
            <div>
              <span
                style="width: 80px; text-align: right; display: inline-block;margin-right:4px;"
                >打印时间: </span
              >{{ print_Time }}
            </div>
            <div>
              <span
                style="width: 80px; text-align: right; display: inline-block;margin-right:4px;"
                >运输方式:</span
              >
              {{ item_a.channelTransportName }}
            </div>
            <div>
              <span
                style="width: 80px; text-align: right; display: inline-block;margin-right:4px;"
                >渠道:</span
              >
              {{ item_a.channelName }}
            </div>
          </div>
        </div>
        <div style="margin-top: 10px">
          打包申请备注: {{ item_a.billPackComment || "" }}
        </div>
        <!-- 表格 -->
        <table
          style="
            width: 100%;
            border: 1px solid #666;
            margin-top: 10px;
            border-collapse: collapse;
            font-size: 14px;
          "
        >
          <thead>
            <tr style="text-align: center">
              <th style="border: 1px solid #666">序号</th>
              <th style="border: 1px solid #666">仓库</th>
              <th style="border: 1px solid #666">仓位</th>
              <th style="border: 1px solid #666">货架号</th>
              <th style="border: 1px solid #666">快递单号</th>
              <th style="border: 1px solid #666">客户名称</th>
              <th style="border: 1px solid #666">会员名</th>
              <th style="border: 1px solid #666">货物</th>
              <th style="border: 1px solid #666">数量</th>
              <th style="border: 1px solid #666">重量(KG)</th>
              <!-- <th style="border: 1px solid #666">运输方式</th>
              <th style="border: 1px solid #666">渠道</th> -->
              <th style="border: 1px solid #666">到库备注</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="text-align: center"
              v-for="(item_b, index_b) in item_a.packageInfoList"
              :key="index_b"
            >
              <td style="border: 1px solid #666; word-break: break-all">
                {{ index_b + 1 }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 仓库 -->
                {{ item_b.storageName }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 仓位 -->
                {{ item_b.storageNumber }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 货架号 -->
                {{ item_b.frameName }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 快递单号 -->
                {{ item_b.courierNumber }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 客户名称 -->
                {{ item_b.nickname }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 会员名 -->
                {{ item_b.memberId }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 货物 -->
                {{ item_b.packageItem }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 数量 -->
                {{ item_b.boxCount }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 重量 -->
                {{ item_b.packageWeight }}
              </td>

              <!-- <td style="border: 1px solid #666; word-break: break-all">
                运输方式
                {{ item_b.channelTransportName }}
              </td>
              <td style="border: 1px solid #666; word-break: break-all">
                渠道
                {{ item_b.channelName }}
              </td> -->

              <td style="border: 1px solid #666; word-break: break-all">
                <!-- 到库备注 -->
                {{ item_b.intoStorageComment }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style="
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
          "
        >
          <div style="margin-right: 40px">
            合计数量：{{ item_a.allNum || 0 }}
          </div>
          <div style="margin-right: 40px">
            合计重量：{{ item_a.allWeight || 0 }} KG
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as printJS from "print-js";
// import print_css from "./Print_css.js";
import { Api } from "@/assets/js/api.js";
// import printJS from "print-js";
import JsBarcode from "jsbarcode"; //条形码
import tools from "@/assets/js/tool";
export default {
  components: {},
  data() {
    return {
      billPackIds: [],
      printData: [],
      print_Time: "",
    };
  },
  created() {
    // billPackIds
    if (this.$route.query.ids) {
      // console.log(JSON.parse(this.$route.query.ids));
      this.billPackIds = JSON.parse(this.$route.query.ids);
      this.getData();
    } else {
      this.$message.error("获取id失败");
    }
  },
  mounted() {},
  watch: {},
  methods: {
    createCode(id, data) {
      JsBarcode(id, data, {
        format: "code128", //选择要使用的条形码类型
        width: 2, //设置条之间的宽度
        height: 70, //高度
        displayValue: false, //是否在条形码下方显示文字
        // text: "456", //覆盖显示的文本
        // fontOptions: "bold italic", //使文字加粗体或变斜体
        // font: "fantasy", //设置文本的字体
        // textAlign: "left", //设置文本的水平对齐方式
        // textPosition: "top", //设置文本的垂直位置
        // textMargin: 5, //设置条形码和文本之间的间距
        fontSize: 14, //设置文本的大小
        background: "#ffffff", //设置条形码的背景
        lineColor: "#222222", //设置条和文本的颜色。
        // margin: 4, //设置条形码周围的空白边距
      });
    },
    getData() {
      let that = this;
      Api.pickingPrint({ billPackIds: this.billPackIds }).then((res) => {
        let datas = res.data.result || [];
        for (var a = 0; a < datas.length; a++) {
          let arr1 = datas[a].packageInfoList || [];
          let weights = 0;
          let nums = 0;
          for (var b = 0; b < arr1.length; b++) {
            arr1[b].channelTransportName = datas[a].channelTransportName;
            arr1[b].channelName = datas[a].channelName;
            let w = arr1[b].packageWeight || 0;
            let n = arr1[b].boxCount * 1 || 0;
            weights = tools.add(weights, w);
            nums = tools.add(nums, n);
          }
          datas[a].allWeight = tools.mathToFixed(weights, 2);
          datas[a].allNum = tools.mathToFixed(nums, 2);
        }
        this.printData = JSON.parse(JSON.stringify(datas));
        this.$nextTick(() => {
          for (var i = 0; i < this.printData.length; i++) {
            //生成条形码
            that.createCode("#barcodes_" + i, this.printData[i].billPackNumber);
          }
        });
      });
    },
    //打印
    billPrintClick() {
      let that = this;
      let timer = tools.getCurrentTime();
      this.print_Time = timer;

      setTimeout(() => {
        that.$print(this.$refs.print);
      }, 10);

      // npm 安装的插件(插件只能预览一页,放弃)

      // const style = "@page {margin:0 10mm};"; //打印时去掉页眉页脚
      // let style = print_css.printCssStr;
      // 最好使用原生的table,使用框架会有问题
      // printJS({
      //   printable: "printBill", //标签id
      //   type: "html",
      //   targetStyle: ["*"],
      //   style: style,
      //   scanStyles: false,
      // });
      // 注意事项: 打印html时没有css样式的, 1 可以导入string类型的css,2在标签上面写样式

      // printable:要打印的id。
      // type:可以是 html 、pdf、 json 等。
      // properties:是打印json时所需要的数据属性。
      // gridHeaderStyle和gridStyle都是打印json时可选的样式。
      // repeatTableHeader:在打印JSON数据时使用。设置为时false，数据表标题将仅在第一页显示。
      // scanStyles:设置为false时，库将不处理应用于正在打印的html的样式。使用css参数时很有用。
      // targetStyles: [’*’],这样设置继承了页面要打印元素原有的css属性。
      // style:传入自定义样式的字符串，使用在要打印的html页面 也就是纸上的样子。
      // ignoreElements：传入要打印的div中的子元素id，使其不打印。非常好用
    },
  },
};
</script>

<style scoped lang="scss">
.btn_e6a23c {
  background-color: #e6a23c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  position: fixed;
  top: 80px;
  right: 40px;
  z-index: 99;
}
// 预览样式不一样
.mainBox {
  width: 80% !important;
  min-width: 700px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  page-break-after: always; //强制分页,(该div之后的内容分页, 使用display:flex会无效,浮动也无效)
}

.topTips {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topLeft {
  display: flex;
  align-items: center;
}
.qrcode {
  text-align: center;
  margin-right: 40px;
}
.qrcodeImg {
  width: 200px;
  height: 60px;
  line-height: 60px;
  background-color: #333;
}
.mainTitle {
  text-align: center;
  width: 100%;
}
// 打印预览的样式
@media print {
  // 去掉页眉页脚
  @page {
    margin: 0 10mm;
  }
}
.toCentre {
  background-color: #ccc;
}
</style>
